import { FC, useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Call as TwilioCall } from '@twilio/voice-sdk'
import { NotebookPen, UsersRound } from 'lucide-react'
import SVG from 'react-inlinesvg'
import { useVoip } from '../../../hooks/voip'
import Notch from './Notch'
import HandleInboundVoipCallWindow from './HandleInboundVoipCallWindow'
import CampaignDetailsWindow from './CampaignDetailsWindow'
import VoipInboundCallDetailsWindow from './VoipInboundCallDetailsWindow'
import VoipOutboundCallDetailsWindow from './VoipOutboundCallDetailsWindow'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../components/ui/tabs'
import VoipParticipants, { VoipParticipant } from './VoipParticipants'
import { usePusherEvent } from '../../../hooks/pusher'
import { Keypad } from '../../../components/ui/keypad'
import keypadIcon from '../../../assets/svg/keypad-icon.svg'

const State = TwilioCall.State

const VoipWindow: FC = () => {
  const { campaign, inboundCall, inboundCallAccepted, outboundCall, userLead, devMode } = useVoip()

  const [showCampaignDetailsWindow, setShowCampaignDetailsWindow] = useState(false)
  const toggleShowCampaignDetailsWindow = () => {
    setShowCampaignDetailsWindow(!showCampaignDetailsWindow)
  }

  // Close campaign details window when there is an inbound call
  useEffect(() => {
    if (inboundCall?.status() !== State.Closed) {
      setShowCampaignDetailsWindow(false)
    }
  }, [inboundCall])

  console.log(inboundCall?.status())

  const showHandleInboundCallWindow = inboundCall?.status() === State.Pending
  const showCallDetailsWindow =
    inboundCall?.status() === State.Open ||
    (inboundCallAccepted && inboundCall?.status() === State.Closed)
  const showVoipOutboundCallDetailsWindow = !!userLead

  const windowOpen =
    showCampaignDetailsWindow ||
    showHandleInboundCallWindow ||
    showCallDetailsWindow ||
    showVoipOutboundCallDetailsWindow

  const [activeTab, setActiveTab] = useState('notes')

  const open =
    inboundCall?.status() === State.Open ||
    outboundCall?.status() === State.Open ||
    outboundCall?.status() === State.Connecting

  useEffect(() => {
    if (!open) setActiveTab('notes')
  }, [open])

  const [campaignUserLead, setCampaignUserLead] = useState(undefined)

  usePusherEvent({
    channel: 'private-voip',
    event: 'user-lead-created',
    onEvent: (data) => {
      console.log(data)
      setCampaignUserLead(data)
    },
  })

  const [participants, setParticipants] = useState<VoipParticipant[]>([])

  usePusherEvent({
    channel: 'private-voip',
    event: 'participants',
    onEvent: setParticipants,
  })

  const [keypadHistory, setKeypadHistory] = useState('')
  const onKeyPress = (key: string) => {
    if (inboundCall) inboundCall.sendDigits(key)
    if (outboundCall) outboundCall.sendDigits(key)
    setKeypadHistory(keypadHistory + key)
  }

  return (
    <>
      <div className="z-10 w-[755px] ">
        <Notch
          campaign={campaign}
          showCampaignDetailsWindow={showCampaignDetailsWindow}
          toggleShowCampaignDetailsWindow={toggleShowCampaignDetailsWindow}
          inboundCall={inboundCall}
          outboundCall={outboundCall}
          userLead={userLead}
        />
        <AnimatePresence>
          {windowOpen && (
            <motion.div
              key="box"
              layout
              initial={{ y: 0, scaleY: 1, opacity: 1, height: 0 }}
              animate={{ y: 0, scaleY: 1, opacity: 1, height: 'auto' }}
              exit={{ y: 0, scaleY: 1, opacity: 1, height: 0 }}
              transition={{
                duration: 0.2,
                layout: { ease: 'linear' },
              }}
            >
              <div className="mb-[18px] h-[470px] w-[755px] bg-white p-8">
                {showHandleInboundCallWindow ? (
                  <HandleInboundVoipCallWindow inboundCall={inboundCall!} campaign={campaign} />
                ) : campaign && showCampaignDetailsWindow ? (
                  <CampaignDetailsWindow
                    show={showCampaignDetailsWindow}
                    setShow={setShowCampaignDetailsWindow}
                    campaign={campaign}
                  />
                ) : (
                  <Tabs value={activeTab} onValueChange={setActiveTab}>
                    <TabsList className="mb-8 grid w-full grid-cols-3">
                      <TabsTrigger value="notes" className="flex gap-2">
                        <NotebookPen size={12} className="mt-[1px]" />
                        Notes
                      </TabsTrigger>
                      <TabsTrigger value="participants" className="flex gap-2" disabled={!open}>
                        <UsersRound size={12} className="mt-[1px]" />
                        {open ? (
                          <>Participants ({devMode ? 2 : participants.length + 1})</>
                        ) : (
                          'Participants'
                        )}
                      </TabsTrigger>
                      <TabsTrigger value="keypad" className="flex gap-2" disabled={!open}>
                        <SVG src={keypadIcon} width={8} height={10} className="mt-[2px]" />
                        Keypad
                      </TabsTrigger>
                    </TabsList>
                    <TabsContent value="notes">
                      {showCallDetailsWindow && (
                        <VoipInboundCallDetailsWindow
                          inboundCall={inboundCall!}
                          userLead={campaignUserLead}
                        />
                      )}
                      {showVoipOutboundCallDetailsWindow && (
                        <VoipOutboundCallDetailsWindow outboundCall={outboundCall} />
                      )}
                    </TabsContent>
                    <TabsContent value="participants">
                      <VoipParticipants participants={participants} />
                    </TabsContent>
                    <TabsContent value="keypad">
                      <div className="flex w-full flex-col items-center justify-center">
                        <p className="mb-10 min-h-[23.5px] max-w-[500px] break-words text-[18px] font-semibold">
                          {keypadHistory}
                        </p>
                        <Keypad onKeyPress={onKeyPress} />
                      </div>
                    </TabsContent>
                  </Tabs>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {windowOpen && (
          <motion.div
            style={{ position: 'relative', zIndex: 2 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.4 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div
              className="fixed inset-0 bg-[--colors-modal--backdrop]"
              id="pulsar-modal--backdrop"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default VoipWindow
