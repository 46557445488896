import { DateTime } from 'luxon'

export const getAge = (birthdate: DateTime) => {
  const today = DateTime.local().setZone('America/New_York')
  return Math.floor(today.diff(birthdate, 'years').toObject().years || 0)
}

export const getSubscriptionDate = (subscriptionDate: number) => {
  return DateTime.fromSeconds(subscriptionDate).toFormat('M/d/yyyy')
}
export const getLocalizedDate = (dateTime: DateTime) =>
  dateTime.toLocaleString(undefined, { locale: 'en-US' })

export const getLocalizedTime = (dateTime: DateTime) =>
  dateTime.toLocaleString(
    { hour: 'numeric', minute: '2-digit', hourCycle: 'h11' },
    { locale: 'en-US' }
  )

export const getBirthdate = (birthdate: string) =>
  DateTime.fromISO(birthdate).toUTC().setLocale('en-US').toLocaleString()

export const getTimeSinceGeneration = (creationTime: DateTime, hideAgo?: boolean) => {
  let generationString = ''
  const today = DateTime.local().setZone('America/New_York')
  const difference = today.diff(creationTime, ['years', 'days', 'hours', 'minutes'])

  if (difference.years >= 1) {
    generationString += `${difference.years}y`
    if (difference.days > 0) {
      generationString += ` ${difference.days}d`
    }
  } else if (difference.years < 1) {
    if (difference.days > 0) {
      generationString += ` ${difference.days}d`
    } else if (difference.hours > 0) {
      generationString += `${difference.hours}h`
    } else {
      generationString +=
        Math.round(difference.minutes) <= 0 ? 'Now' : `${Math.round(difference.minutes)}m`
    }
  }
  return !hideAgo && generationString !== 'Now' ? generationString + ' ago' : generationString
}

export const formatDuration = (seconds: number) => {
  if (!seconds) {
    return null
  }

  let remainingSeconds = seconds

  const days = Math.floor(remainingSeconds / 86400)
  remainingSeconds = days ? remainingSeconds % (days * 86400) : remainingSeconds

  const hours = Math.floor(remainingSeconds / 3600)
  remainingSeconds = hours ? remainingSeconds % (hours * 3600) : remainingSeconds

  const minutes = Math.floor(remainingSeconds / 60)
  remainingSeconds = minutes ? remainingSeconds % (minutes * 60) : remainingSeconds

  return `${days ? days + 'd ' : ''}${hours ? hours + 'h ' : ''}${
    minutes ? minutes + 'm ' : ''
  }${remainingSeconds}s`
}

export const formatCallDuration = (duration: number) => {
  const hours = Math.floor(duration / 3600)
  const mins = Math.floor((duration - hours * 3600) / 60)
  const seconds = duration - hours * 3600 - mins * 60

  let dur = ''

  if (hours) dur = hours + 'hr'
  if (mins) {
    if (hours) dur += ''
    dur += mins + 'm'
  }
  if (seconds) {
    if (hours || mins) dur += ' '
    dur += seconds + 's'
  }

  return dur
}

export const formatDate = (dateString: string) => {
  const today = DateTime.now().startOf('day')
  const yesterday = today.minus({ days: 1 })
  const thisWeek = today.minus({ days: 6 })
  const date = DateTime.fromISO(dateString, { setZone: false })
  if (date >= today) {
    return date.toFormat('t')
  } else if (date >= yesterday) {
    return 'Yesterday'
  } else if (date >= thisWeek) {
    return date.toFormat('cccc')
  } else {
    return date.toFormat('LL/dd/yyyy')
  }
}

export const getListOfDaysAvailable = ({
  daysAvailable,
}: {
  daysAvailable: { day_of_week: number }[]
}) => {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  return daysAvailable.map((day) => daysOfWeek[day.day_of_week])
}

export const convertUnixTimeStampToDateAndTime = ({ timestamp }: { timestamp: number }) => {
  const date = new Date(timestamp * 1000)

  // Format the date part (e.g., "August 30, 2024")
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'America/New_York',
  })
  const formattedDate = dateFormatter.format(date)

  // Format the time part (e.g., "10:00am")
  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'America/New_York',
  })

  const formattedTime = timeFormatter.format(date)

  return `${formattedDate} ${formattedTime}`
}

export const covertUnixTimeStampToDay = ({
  timestamp,
  timezone,
}: {
  timestamp: number
  timezone: string
}) => {
  const date = new Date(timestamp * 1000)

  // Format the date part (e.g., "August 30, 2024")
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: timezone,
  })

  return dateFormatter.format(date)
}

export const convertUnixTimeStampToTime = ({
  timestamp,
  timezone,
}: {
  timestamp: number
  timezone: string
}) => {
  // Convert the Unix timestamp to milliseconds
  const date = new Date(timestamp * 1000)

  // Format the time part (e.g., "10:00am")
  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: timezone,
  })

  const formattedTime = timeFormatter.format(date)

  return formattedTime
}

export function getTimeZoneAbbr({ timeZoneName }: { timeZoneName: string }) {
  const dt = DateTime.now().setZone(timeZoneName)
  return dt.isValid ? dt.toFormat('ZZZZ') : ''
}
