import React, { useContext } from 'react'
import { Box, Stack } from '@leadrilla/pulsar'
import styled from '@emotion/styled'

import LeadInfo from './LeadInfo'
import Timeline from './Timeline'
import DialLead from './DialLead'
import Notes from './Notes'
import { CallContext } from './CallContext'

const CallWindow: React.FC = () => {
  const callContext = useContext(CallContext)

  return (
    <Box display={['block', 'flex']} justifyContent="between">
      <StyledBox
        height={['calc(100vh - 420px)', '470px']}
        width="100%"
        paddingLeft="l"
        paddingRight="s"
        paddingY="l"
      >
        <Stack space="m">
          <LeadInfo />
          <Timeline />
        </Stack>
      </StyledBox>

      <StyledBox width="100%" paddingLeft="s" paddingRight="l" paddingY="l">
        {callContext.call?.active && !callContext.call.accepted ? <DialLead /> : <Notes />}
      </StyledBox>
    </Box>
  )
}
const StyledBox = styled(Box)`
  overflow-y: auto;
  max-height: min-content;
`
export default CallWindow
