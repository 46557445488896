import { useEffect, useState } from 'react'
import { Device } from '@twilio/voice-sdk'
import { Headset } from 'lucide-react'
import { Dialog, Dropdown, FieldLabel, Heading, Icon } from '@leadrilla/pulsar'
import { useVoip } from '../../hooks/voip'
import { useShouldVoipBeEnabled } from '../../hooks/queries/shouldVoipBeEnabled'
import { useNotification } from '../../hooks/notification'

const AudioDeviceManager = () => {
  const [inputDevices, setInputDevices] = useState<MediaDeviceInfo[]>([])
  const lastSelectedInputDeviceId = localStorage.getItem('last-selected-input-device-id')
  const [selectedInputDeviceId, setSelectedInputDeviceId] = useState<string>(
    lastSelectedInputDeviceId ?? 'default'
  )

  const [outputDevices, setOutputDevices] = useState<MediaDeviceInfo[]>([])
  const lastSelectedOutputDeviceId = localStorage.getItem('last-selected-output-device-id')
  const [selectedOutputDeviceId, setSelectedOutputDeviceId] = useState<string>(
    lastSelectedOutputDeviceId ?? 'default'
  )
  // Safari doesn't provide access to audio output devices
  const [outputDevicesUnavailable, setOutputDevicesUnavailable] = useState(false)

  const selectedDeviceSetterMapping = {
    input: setSelectedInputDeviceId,
    output: setSelectedOutputDeviceId,
  }

  const onSelectedDeviceChanged = (deviceType: 'input' | 'output', deviceId: string) => {
    selectedDeviceSetterMapping[deviceType](deviceId)
    localStorage.setItem(`last-selected-${deviceType}-device-id`, deviceId)
  }

  const testOutputDevice = () => {
    const audio = new Audio('https://sdk.twilio.com/js/client/sounds/releases/1.0.0/outgoing.mp3')
    try {
      ;(audio as any).setSinkId(selectedOutputDeviceId).then(() => audio.play())
    } catch (err) {
      // Browser doesn't support HTMLMediaElement.setSinkId()
      // Play using the default audio device
      audio.play()
    }
  }

  const updateAudioDevices = async () => {
    const inputs = []
    const outputs = []

    let selectedInputFound,
      selectedOutputFound = false

    let defaultInputDeviceFound = false

    const devices = await navigator.mediaDevices.enumerateDevices()

    for (const device of devices) {
      if (device.kind === 'audioinput') {
        inputs.push(device)
        if (device.deviceId === selectedInputDeviceId) {
          selectedInputFound = true
        }
        if (device.deviceId === 'default') {
          defaultInputDeviceFound = true
        }
      }
      if (device.kind === 'audiooutput') {
        outputs.push(device)
        if (device.deviceId === selectedOutputDeviceId) {
          selectedOutputFound = true
        }
      }
    }

    setInputDevices(inputs)
    if (!selectedInputFound) {
      if (defaultInputDeviceFound) {
        setSelectedInputDeviceId('default')
      } else {
        // Safari doesn't provide an input device with deviceId === 'default', use the first device listed instead
        const defaultInputDeviceId = inputs[0].deviceId
        if (defaultInputDeviceId) {
          setSelectedInputDeviceId(defaultInputDeviceId)
        }
      }
    }

    if (outputs.length > 0) {
      setOutputDevices(outputs)
    } else {
      // Safari doesn't provide access to audio output devices. Add a dummy output device for display in the disabled dropdown.
      setOutputDevices([
        {
          deviceId: 'default',
          label: 'Default Output Device',
          kind: 'audiooutput',
          groupId: 'default',
          toJSON: () => {},
        },
      ])
      setOutputDevicesUnavailable(true)
    }
    if (!selectedOutputFound) {
      setSelectedOutputDeviceId('default')
    }
  }

  const promptForMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      stream.getTracks().forEach((track) => track.stop())
    } catch (err) {
      // Permission has already been denied
      // @ts-expect-error FIXME
      showNotification({
        type: 'caution',
        message:
          'Microphone permissions have been denied. In-browser calls will not function until you grant microphone permissions.',
      })
    }
  }

  const showNotification = useNotification()

  const handleMicrophonePermissionState = (state: PermissionState): any => {
    if (state === 'granted') {
      updateAudioDevices()
    } else if (state === 'prompt') {
      promptForMicrophonePermission()
    } else if (state === 'denied') {
      // @ts-expect-error FIXME
      showNotification({
        type: 'caution',
        message:
          'Microphone permissions have been denied. In-browser calls will not function until you grant microphone permissions.',
      })
    }
  }

  const checkForMicrophonePermission = async () => {
    // @ts-ignore
    const result = await navigator.permissions.query({ name: 'microphone' })
    result.onchange = () => handleMicrophonePermissionState(result.state)
    handleMicrophonePermissionState(result.state)
  }

  const { data } = useShouldVoipBeEnabled()

  // Check for microphone permission if the user has a call campaign
  useEffect(() => {
    if (data?.call_campaign_exists) {
      checkForMicrophonePermission()

      navigator.mediaDevices.addEventListener('devicechange', updateAudioDevices)
      return () => {
        navigator.mediaDevices.removeEventListener('devicechange', updateAudioDevices)
      }
    }
  }, [data])

  const [audioDeviceManagerModalOpen, setAudioDeviceManagerModalOpen] = useState(false)

  // Also check for microphone permission when the audio settings modal is opened
  // because somewhat recent versions of Safari don't trigger the 'change' event when permissions are granted.
  useEffect(() => {
    if (audioDeviceManagerModalOpen) checkForMicrophonePermission()
  }, [audioDeviceManagerModalOpen])

  const { device } = useVoip()

  const setAudioDevices = async (device: Device | undefined) => {
    try {
      if (device?.audio) {
        await Promise.all([
          device.audio.setInputDevice(selectedInputDeviceId),
          device.audio.speakerDevices.set(selectedOutputDeviceId),
          device.audio.ringtoneDevices.set(selectedOutputDeviceId),
        ])
      }
    } catch {}
  }

  useEffect(() => {
    setAudioDevices(device)
  }, [device, selectedInputDeviceId, selectedOutputDeviceId])

  const deviceOptions = (devices: MediaDeviceInfo[]) =>
    devices.map((device) => ({
      text: device.label.replace(/ *\([^)]*\) */g, ''),
      value: device.deviceId,
    }))

  if (!data?.call_campaign_exists) return null

  return (
    <>
      <Headset
        className="cursor-pointer"
        onClick={() => setAudioDeviceManagerModalOpen(!audioDeviceManagerModalOpen)}
      />

      <Dialog
        open={audioDeviceManagerModalOpen}
        onClose={() => setAudioDeviceManagerModalOpen(false)}
        overflow="visible"
      >
        <div className="flex w-[348px] flex-col gap-[24px] p-[16px]">
          <div className="flex justify-between">
            <Heading level={3}>Audio Settings</Heading>
            <div className="cursor-pointer" onClick={() => setAudioDeviceManagerModalOpen(false)}>
              <Icon name="close" tone="light" size="m" />
            </div>
          </div>

          <Dropdown
            label="Microphone"
            options={deviceOptions(inputDevices)}
            value={selectedInputDeviceId}
            onChange={(deviceId) => onSelectedDeviceChanged('input', deviceId)}
          />

          <div className="flex flex-col gap-[8px]">
            <div className="flex justify-between">
              <FieldLabel label="Speakers" />
              <div
                className="bold cursor-pointer text-[--colors-action]"
                onClick={testOutputDevice}
              >
                Test
              </div>
            </div>
            <Dropdown
              options={deviceOptions(outputDevices)}
              value={selectedOutputDeviceId}
              onChange={(deviceId) => onSelectedDeviceChanged('output', deviceId)}
              disabled={outputDevicesUnavailable}
            />
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default AudioDeviceManager
