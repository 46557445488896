import { TenantConfig } from './hooks/TenantConfig'

const defaultConfig: TenantConfig = {
  display_name: 'Leadrilla',
  tenant_url: process.env.LEADRILLA_PUBLIC_URL || '',
  tenant_went_live_at: '2024-01-24 13:18:00.000 -0500',
  title: 'All-in-One Sales Platform for Distributed Teams - SalesRiver',
  stripe: {
    application_fee: 0,
    charge_account: 'platform',
    statement_descriptor: 'Demo',
    connected_stripe_account_id: '',
    stripe_receipts: false,
  },
  teams: {
    enabled: true,
    default_members_table_filters: {
      role: 'show-members',
    },
  },
  campaigns: {
    enabled: true,
    ping_mode: 'bidding',
    call_charge_mode: 'answer',
    call_auto_refund: true,
    hide_unconverted_calls: false,
    ipp_idle_timeout: 0,
    max_campaigns_per_call: 5,
    duplicate_lead_blocking_window: 30,
    blacklist_alliance_scrub: false,
    call_campaign_voip_show_pause_reason_modal: false,
  },
  subscriptions: { enabled: true, stripe_account: 'platform', price: 50000 },
  marketplace: {
    enabled: true,
    second_chance: true,
    times_sold: 4,
    days_until_available: 21,
    pricing: {
      model: 'multiplier',
      tiers: [
        { start: 0, end: 30, multiplier: 0.55 },
        { start: 31, end: 60, multiplier: 0.35 },
        { start: 61, end: 90, multiplier: 0.2 },
        { start: 91, end: 180, multiplier: 0.1 },
        { start: 181, end: 365, multiplier: 0.05 },
      ],
    },
  },
  product_settings: { enabled: false },
  webhook_settings: { enabled: false },
  signup: {
    invite_only: false,
    collect_npn: false,
    collect_industry: false,
    collect_company: false,
    collect_organization: false,
    organization_display_name: '',
  },
  terms: {
    company_name: '',
    site: '',
    address: '',
  },
  emails: {
    support_email: '',
    noreply_email: 'noreply@leadrilla.com',
    lead_communication_email: 'noreply@leadrilla.com',
    logo_url: 'https://email-assets-wl.s3.amazonaws.com/leadrilla-logo.png',
    dark_logo_url: 'https://email-assets-wl.s3.amazonaws.com/leadrilla-logo.png',
    logo_width: 220,
    logo_height: 22,
    primary_color: '#4F77D8',
    address: {
      street: '401 W Main St Suite 204',
      city: 'Lexington',
      state: 'KY',
      zip: '40507',
    },
  },
  sms_from: '+15022008870',
  voice: {
    enabled: false,
    cost_per_minute: 0,
  },
  referral_program: {
    enabled: true,
    referrer_amount: 50_00,
    referee_amount: 25_00,
    required_spend_amount: 200_00,
    link_only: false,
  },
  custom_menu_links: [],
  logos: {
    dashboard_main: {
      width: '',
      height: '',
    },
  },
  themeColor: '#4F77D8',
  theme: {
    transition: '.2s',
    brand: {
      background: '#EFF1F5',
      foreground: '#FFFFFF',
      modalBackdrop: '#0E1118',
      hairline: '#EFF1F5',
      highlight: '#F7F8FA',
      neutral: '#26292F',
      subtle: '#CFCFD1',
      light: '#86888B',
      action: '#4F77D8',
      actionSubtle: '#DCE4F7',
      actionLight: '#B9C9EF',
      info: '#4F77D8',
      infoSubtle: '#DCE4F7',
      infoLight: '#B9C9EF',
      positive: '#69AD78',
      positiveSubtle: '#E1EFE4',
      negative: '#D95656',
      negativeSubtle: '#F7DDDD',
      caution: '#F4BA61',
      cautionSubtle: '#FDF1DF',
      cautionDark: '#C08A00',
      contrast: '#FAFAFC',
      iconBase: '#26292F',
      iconAccent: '#4F77D8',
    },
    card: {
      bg: '#FAFAFC',
      hairline: '#EFF1F5',
      border: '#F7F8FA',
    },
    button: {
      text: {
        primary: '#FFFFFF',
        secondary: '#4F77D8',
        destructive: '#FFFFFF',
        link: '#4F77D8',
      },
      bg: {
        primary: '#4F77D8',
        secondary: '',
        destructive: '#D95656',
      },
      hover: {
        primary: '#5B8AFF',
        secondary: '#DCE4F7',
        destructive: '#FB5B5B',
        link: '#DCE4F7',
      },
      border: {
        primary: '#4F77D8',
        secondary: '#4F77D8',
        destructive: '#D95656',
        link: 'transparent',
      },
    },
    'choice-chip': {
      text: {
        default: '#86888B',
        active: '#4F77D8',
        disabled: '#CFCFD1',
      },
      hover: {
        default: '#4F77D8',
      },
      bg: {
        default: '#EFF1F5',
        active: '#DCE4F7',
        disabled: '#FAFAFC',
      },
      border: {
        default: '#EFF1F5',
        active: '#DCE4F7',
        disabled: '#FAFAFC',
      },
    },
    'input-chip': {
      bg: {
        neutral: '#DCE4F7',
        negative: '#F7DDDD',
        positive: '#E1EFE4',
      },
    },
    textfield: {
      bg: {
        disabled: '#F7F8FA',
      },
      border: {
        neutral: '#CFCFD1',
        negative: '#F0BBBB',
      },
    },
    font: {
      name: 'Assistant',
      url: 'https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;500;600;700;800&display=swap',
    },
    tabs: {
      bg: {
        default: 'transparent',
        chip: '#F7F8FA',
        active: '#DCE4F7',
      },
    },
  },
  mapTheme: {
    0.2: '#4F77D8',
    0.4: '#69AD78',
    0.6: '#F4BA61',
    0.8: '#D95656',
  },
  call_campaign_video_id: '',
  enable_lead_print: false,
  ipp_enabled_at: new Date(),
  platform_presence: false,
  organizations: {
    enabled: true,
    collect_during_signup: true,
    display_name: 'Leadrilla',
  },
  enabledStates: [],
  disableMarketplace: false,
  display_powered_by_logo: false,
  freshchat_token: '',
  extra_terms: {
    name: '',
    url: '',
  },
  sms_checkbox: false,
  hide_statewide_targeting: false,
  hide_radius_targeting: false,
}

export default defaultConfig
