import { DateTime } from 'luxon'
import { UserLead } from '../types/user_lead'

export const getVoiceTwilioNumber = ({ user }: { user: any }) => {
  return user?.twilio_number?.filter((num: any) => num.type === 'voice')[0]?.number || ''
}

export const getLeadBirthdate = ({ userLead }: { userLead: UserLead }) => {
  if (!userLead.birthdate) return ''
  const birthdate = DateTime.fromISO(userLead.birthdate)
  if (birthdate.isValid) return birthdate.toISODate() as string
  return ''
}
