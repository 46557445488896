// @ts-nocheck FIXME
import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { tooltipClasses } from '@mui/material'
import { Box, Stack, Text } from '@leadrilla/pulsar'
import { default as MuiTooltip } from '@mui/material/Tooltip'

function Tooltip({ title, body, placement = 'bottom', ...props }) {
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  const content = (
    <Box position="relative" display="inline-block">
      <Stack space="s">
        <Box maxWidth="200">
          <Text tone="ghost" size="base" weight="strong">
            {title}
          </Text>
        </Box>
        <Text component="p" tone="ghost" size="small" pLineHeight="1.5">
          {body}
        </Text>
      </Stack>
    </Box>
  )

  return (
    <StyledTooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      placement={placement}
      enterTouchDelay={250}
      title={content}
    >
      <StyledSpan>{props.children}</StyledSpan>
    </StyledTooltip>
  )
}

const StyledSpan = styled('span')`
  color: var(--colors-action);
`

const StyledTooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#86888B',
    color: '#fff',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(12),
    padding: '16px',
    borderRadius: '16px',
    display: 'inline-block',
  },
}))

export default Tooltip
