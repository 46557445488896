import { useQuery } from '@tanstack/react-query'
import backend from '../../backend'

interface State {
  id: number
  name: string
  abbreviation: string
}

const fetchStates = async (): Promise<State[]> => {
  const { body } = await backend.get('/states')
  return body
}

export const useStates = () => {
  return useQuery<State[]>({
    queryKey: ['states'],
    queryFn: fetchStates,
    cacheTime: Infinity,
  })
}
