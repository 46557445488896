import { Badge } from '@leadrilla/pulsar'
import { FallbackProps } from 'react-error-boundary'

const ComponentError: React.FC<FallbackProps> = (props) => {
  console.error(props.error)
  return (
    <div>
      <Badge tone="negative">Error</Badge>
    </div>
  )
}

export default ComponentError
