import { Fragment, SetStateAction, Dispatch } from 'react'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { Divider, Text, TextLink } from '@leadrilla/pulsar'
import { LeadFeed } from '../../../types/lead_feed'
import { TargetedState } from '../../../types/targeted_state'
import CallHistory from '../../CallBar/CallHistory'

const CampaignDetailsWindow = ({
  show,
  setShow,
  campaign,
}: {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  campaign: LeadFeed
}) => {
  const history = useHistory()

  const goToCalls = () => {
    setShow(false)
    history.push(`/dash/my-leads`, {
      dataTables: [
        {
          name: 'my-leads-table',
          selected: [
            {
              key: 'product',
              selected: {
                value: campaign.product!.id,
                text: campaign.product!.name,
              },
            },
          ],
        },
      ],
    })
  }

  return (
    <div className={cn({ hidden: !show }, 'flex h-full gap-[24px]')}>
      <div className="flex w-1/2 flex-col gap-[24px]">
        <div className="flex flex-col gap-4">
          <Text tone="light">Campaign Name</Text>
          <Text size="bigger" weight="strong">
            {campaign.name}
          </Text>
        </div>
        <Divider />
        <div className="flex flex-col gap-4">
          <Text tone="light">Geography</Text>
          <Text size="bigger" weight="strong">
            {campaign.states!.map((state: TargetedState, i: number, arr: TargetedState[]) => {
              return (
                <Fragment key={state.id}>
                  {i === arr.length - 1 ? (
                    <span>{state.state}</span>
                  ) : (
                    <span>{state.state},&nbsp;</span>
                  )}
                </Fragment>
              )
            })}
          </Text>
        </div>
      </div>
      <div className="h-full w-[1px] bg-[--colors-hairline]" />
      <div className="flex w-1/2 flex-col gap-[24px]">
        <div className="flex items-center justify-between">
          <Text weight="stronger" size="bigger">
            Recent Calls
          </Text>
          <Text align="right">
            <div onClick={goToCalls}>
              {
                // @ts-ignore
                <TextLink>View all calls</TextLink>
              }
            </div>
          </Text>
        </div>
        <CallHistory campaign={campaign} history={history} setShowInfo={setShow} />
      </div>
    </div>
  )
}

export default CampaignDetailsWindow
