import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import backend from '../../backend'

interface LeadStatuses {
  [key: string]: {
    name: string
    tone: string
  }
}

const fetchLeadStatuses = async ({ queryKey }: QueryFunctionContext): Promise<LeadStatuses> => {
  const [, productId] = queryKey
  const { body } = await backend.get(`/leads/${productId}/statuses`)
  return body
}

export const useLeadStatuses = (productId?: string) => {
  return useQuery<LeadStatuses>({
    queryKey: ['lead-statuses', productId ?? 'all'],
    queryFn: fetchLeadStatuses,
  })
}
