import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import ComponentError from './component_error'
import PageError from './page_error'
import AppError from './app_error'
import * as Sentry from '@sentry/react'

interface ErrorBoundaryProps {
  level: 'app' | 'page' | 'component'
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = (props) => {
  let FallbackComponent = ComponentError

  if (props.level === 'page') {
    FallbackComponent = PageError
  } else if (props.level === 'app') {
    FallbackComponent = AppError
  }

  return (
    <ReactErrorBoundary FallbackComponent={FallbackComponent} onError={errorHandler}>
      {props.children}
    </ReactErrorBoundary>
  )
}

ErrorBoundary.defaultProps = {
  level: 'component',
}

const errorHandler = (error: Error) => {
  Sentry.captureException(error)
}

export default ErrorBoundary
