// TODO - improve types to ensure that the User actually has an array of Teams
// TODO - write tests
export const isUserAdminForTeam = ({ user, teamId }: { user: any; teamId: string }) => {
  if (!user || !user.teams) return false

  const team = user.teams.find((team: any) => team.id === teamId)
  return team && team.is_admin
}

export const isAdminForAtLeastOneTeam = ({ teams }: { teams: any }) => {
  return teams.some((team: any) => team.is_admin)
}
