// TODO - Should figure out a way to generate the subtle, light, dark, etc. color variations from a single color. We should be able to override the specific color variations, but the config editor(?) should be able to work by providing just a single primary color and a single secondary color. Review how MUI handles theming.

export const COLORS = {
  valhalla: {
    subtle: '#DCE4F7',
    light: '#B9C9EF',
    standard: '#4F77D8',
    hover: '#5B8AFF',
  },
  bethpage: {
    subtle: '#CFCFD1',
    light: '#86888B',
    standard: '#26292F',
    dark: '#0E1118',
  },
  whistling: {
    light: '#F7F8FA',
    standard: '#EFF1F5',
  },
  pebble: '#FAFAFC',
  ghost: '#FFFFFF',
  augusta: {
    subtle: '#E1EFE4',
    light: '#C3DEC9',
    standard: '#69AD78',
    dark: '#609D6E',
  },
  merion: {
    subtle: '#F7DDDD',
    light: '#F0BBBB',
    standard: '#D95656',
    dark: '#C44F50',
    hover: '#FB5B5B',
  },
  dune: {
    subtle: '#FDF1DF',
    light: '#F9DBAC',
    standard: '#F4BA61',
    dark: '#C08A00',
  },
  teal: {
    standard: '#3EA590',
    ten: '#ECF7F4',
    thirty: '#C5E4DD',
    sixty: '#8CCABD',
    hover: '#377E67',
  },
  coolGray: {
    ten: '#FAFAFB',
    thirty: '#EDEFF0',
    sixty: '#DCE0E3',
    standard: '#C5CCD0',
  },
  blue: {
    ten: '#EAEDF0',
    thirty: '#BDC6CF',
    sixty: '#7C8EA0',
    standard: '#244260',
  },
  mediumBlue: {
    ten: '#E6EEF2',
    thirty: '#B2C9D7',
    sixty: '#6695B0',
    standard: '#244260',
  },
  green: {
    standard: '#72C596',
    sixty: '#AADCC0',
    thirty: '#D4EDDF',
    ten: '#F1FAF5',
  },
  leadstarPurple: {
    standard: '#7F287F',
    sixty: '#B27EB2',
    thirty: '#D9BFD9',
    ten: '#F2E9F2',
    hover: '#AE37AE',
  },
  leadstarBlack: {
    standard: '#353535',
    sixty: '#868686',
    thirty: '#C2C2C2',
    ten: '#EBEBEB',
  },
} as const

export type ColorValueHex = `#${string}`
export const isColorValueHex = (value: unknown): value is ColorValueHex => {
  if (typeof value === 'string' && value.slice(0, 1) === '#' && value.length === 7) return true
  return false
}

type OptionalColorValueHex = ColorValueHex | '' | undefined

export type Theme = {
  transition: string
  brand: {
    background: OptionalColorValueHex
    foreground: OptionalColorValueHex
    modalBackdrop: OptionalColorValueHex
    hairline: OptionalColorValueHex
    highlight: OptionalColorValueHex
    neutral: OptionalColorValueHex
    subtle: OptionalColorValueHex
    light: OptionalColorValueHex
    action: OptionalColorValueHex
    actionSubtle: OptionalColorValueHex
    actionLight: OptionalColorValueHex
    info: OptionalColorValueHex
    infoSubtle: OptionalColorValueHex
    infoLight: OptionalColorValueHex
    positive: OptionalColorValueHex
    positiveSubtle: OptionalColorValueHex
    negative: OptionalColorValueHex
    negativeSubtle: OptionalColorValueHex
    caution: OptionalColorValueHex
    cautionSubtle: OptionalColorValueHex
    cautionDark: OptionalColorValueHex
    contrast: OptionalColorValueHex
    iconBase: OptionalColorValueHex
    iconAccent: OptionalColorValueHex
  }
  card: {
    bg: OptionalColorValueHex
    hairline: OptionalColorValueHex
    border: OptionalColorValueHex
  }
  button: {
    text: {
      primary: OptionalColorValueHex
      secondary: OptionalColorValueHex
      destructive: OptionalColorValueHex
      link: OptionalColorValueHex
    }
    bg: {
      primary: OptionalColorValueHex
      secondary: OptionalColorValueHex
      destructive: OptionalColorValueHex
    }
    hover: {
      primary: OptionalColorValueHex
      secondary: OptionalColorValueHex
      destructive: OptionalColorValueHex
      link: OptionalColorValueHex
    }
    border: {
      primary: OptionalColorValueHex
      secondary: OptionalColorValueHex
      destructive: OptionalColorValueHex
      link: string
    }
  }
  'choice-chip': {
    text: {
      default: OptionalColorValueHex
      active: OptionalColorValueHex
      disabled: OptionalColorValueHex
    }
    hover: {
      default: OptionalColorValueHex
    }
    bg: {
      default: OptionalColorValueHex
      active: OptionalColorValueHex
      disabled: OptionalColorValueHex
    }
    border: {
      default: OptionalColorValueHex
      active: OptionalColorValueHex
      disabled: OptionalColorValueHex
    }
  }
  'input-chip': {
    bg: {
      neutral: OptionalColorValueHex
      negative: OptionalColorValueHex
      positive: OptionalColorValueHex
    }
  }
  textfield: {
    bg: {
      disabled: OptionalColorValueHex
    }
    border: {
      neutral: OptionalColorValueHex
      negative: OptionalColorValueHex
    }
  }
  font: {
    name: string
    url: string
  }
  tabs: {
    bg: {
      default: string
      chip: OptionalColorValueHex
      active: OptionalColorValueHex
    }
  }
}
