import { createContext, useContext, FC, useReducer, Dispatch, useEffect, useRef } from 'react'
import { default as PusherClient, Channel as PusherChannel } from 'pusher-js/with-encryption'
import { v4 as uuid } from 'uuid'
import { useAuth } from '../components/AuthProvider/auth_provider'
import { useTenant, useTenantConfig } from './TenantConfig'

interface PusherContext {
  connected: boolean
}

const context = createContext<PusherContext>({
  connected: false,
})

interface PusherActionsContext {
  connect: () => void
  subscribeToChannelEvent: (args: {
    channel: ChannelName
    event: string
    onEvent: (data: any) => void
  }) => () => void
  trigger: (args: { channel: ChannelName; event: string; data?: any }) => void
}

const actionsContext = createContext<PusherActionsContext>({
  connect: () => {},
  subscribeToChannelEvent: () => () => {},
  trigger: () => {},
})

interface PusherState {
  connected: boolean
  client?: PusherClient
  channels: Map<ChannelName, PusherChannel>
  eventSubscriptions: { id: string; channel: ChannelName; event: string }[]
}

const createDefaultPusherState: () => PusherState = () => ({
  connected: false,
  channels: new Map(),
  eventSubscriptions: [],
})

export enum PusherActionType {
  SetClient = 'set_client',
  Connected = 'connected',
  Disconnected = 'disconnected',
  AddChannel = 'add_channel',
  RemoveChannels = 'remove_channels',
  SubscribedToChannelEvent = 'subscribed_to_channel_event',
  UnsubscribedFromChannelEvent = 'unsubscribed_from_channel_event',
}

type PusherAction =
  | { type: PusherActionType.SetClient; client: PusherClient }
  | { type: PusherActionType.Connected }
  | { type: PusherActionType.Disconnected }
  | { type: PusherActionType.AddChannel; channelName: ChannelName; channel: PusherChannel }
  | { type: PusherActionType.RemoveChannels; channelNames: ChannelName[] }
  | {
      type: PusherActionType.SubscribedToChannelEvent
      id: string
      channel: ChannelName
      event: string
    }
  | {
      type: PusherActionType.UnsubscribedFromChannelEvent
      id: string
    }

const reducer = (state: PusherState, action: PusherAction): PusherState => {
  switch (action.type) {
    case PusherActionType.SetClient:
      return {
        ...state,
        client: action.client,
      }
    case PusherActionType.Connected:
      return {
        ...state,
        connected: true,
      }
    case PusherActionType.Disconnected:
      return createDefaultPusherState()
    case PusherActionType.AddChannel:
      const channels = state.channels
      channels.set(action.channelName, action.channel)
      return {
        ...state,
        channels,
      }
    case PusherActionType.SubscribedToChannelEvent:
      return {
        ...state,
        eventSubscriptions: [
          ...state.eventSubscriptions,
          { id: action.id, channel: action.channel, event: action.event },
        ],
      }
    case PusherActionType.UnsubscribedFromChannelEvent:
      return {
        ...state,
        eventSubscriptions: state.eventSubscriptions.filter(({ id }) => id === action.id),
      }
    default:
      return state
  }
}

export const PusherProvider: FC = ({ children }) => {
  const { user } = useAuth()
  const [state, dispatch] = useReducer(reducer, createDefaultPusherState())

  useCleanUpChannels(state, dispatch)

  const connect = () => _connect(dispatch)
  const { id: tenantId } = useTenant()

  useEffect(() => {
    if (state.connected && state.client && tenantId) {
      state.client.subscribe(`presence-${tenantId}`)
    }
  }, [state.connected, state.client, tenantId])

  const subscribeToChannelEvent = ({
    channel,
    event,
    onEvent,
  }: {
    channel: ChannelName
    event: string
    onEvent: (data: any) => void
  }) =>
    _subscribeToChannelEvent({
      channelName: channel,
      event,
      onEvent,
      userId: user!.id,
      tenantId,
      state,
      dispatch,
    })

  const trigger = ({ channel, event, data }: { channel: ChannelName; event: string; data?: any }) =>
    _trigger({ channelName: channel, event, data, state })

  const actions = {
    connect,
    subscribeToChannelEvent,
    trigger,
  }

  return (
    <context.Provider value={{ connected: state.connected }}>
      <actionsContext.Provider value={actions}>{children}</actionsContext.Provider>
    </context.Provider>
  )
}

const useCleanUpChannels = (state: PusherState, dispatch: Dispatch<PusherAction>) => {
  const tenantConfig = useTenantConfig()

  useEffect(() => {
    if (state.connected && state.eventSubscriptions.length === 0) {
      if (!tenantConfig.voice.enabled) {
        state.client?.unbind()
        state.client?.disconnect()
        dispatch({ type: PusherActionType.Disconnected })
      }
    } else {
      const channelsToRemove: ChannelName[] = []
      for (const [channelName, channel] of state.channels) {
        const subscriptions = state.eventSubscriptions.filter(
          (subscription) => subscription.channel === channelName
        )
        if (subscriptions.length === 0) {
          channelsToRemove.push(channelName)
          channel.unbind()
          channel.unsubscribe()
        }
      }
      dispatch({ type: PusherActionType.RemoveChannels, channelNames: channelsToRemove })
    }
  }, [state.channels, state.eventSubscriptions])
}

const _connect = (dispatch: Dispatch<PusherAction>) => {
  const token = localStorage.getItem('token')
  PusherClient.logToConsole = process.env.REACT_APP_PUSHER_DEBUG === 'true'
  const client = new PusherClient(process.env.REACT_APP_PUSHER_APP_KEY as string, {
    cluster: 'us2',
    userAuthentication: {
      endpoint: `${process.env.REACT_APP_API_URL}/pusher/user-auth`,
      transport: 'ajax',
      headers: { Authorization: `Bearer ${token}` },
    },
    channelAuthorization: {
      endpoint: `${process.env.REACT_APP_API_URL}/pusher/channel-auth`,
      transport: 'ajax',
      headers: { Authorization: `Bearer ${token}` },
    },
  })

  dispatch({ type: PusherActionType.SetClient, client })

  client.connection.bind('connected', () => {
    client.signin()
  })
  client.connection.bind('disconnect', () => client.disconnect())
  client.bind('pusher:signin_success', () => {
    dispatch({ type: PusherActionType.Connected })
  })
}

type ChannelName = 'private' | 'private-voip' | 'notify_user'

const getFullChannelName = ({
  channelName,
  userId,
  tenantId,
}: {
  channelName: ChannelName
  userId: string
  tenantId: string
}) => {
  return channelName === 'notify_user' ? channelName : `${channelName}-${userId}.${tenantId}`
}

const _subscribeToChannelEvent = ({
  channelName,
  event,
  onEvent,
  userId,
  tenantId,
  state,
  dispatch,
}: {
  channelName: ChannelName
  event: string
  onEvent: (data: any) => void
  userId: string
  tenantId: string
  state: PusherState
  dispatch: Dispatch<PusherAction>
}) => {
  const client = state.client

  if (!client) {
    throw new Error('You must wait for Pusher to be connected before subscribing to a channel.')
  }

  const eventSubscriptionId = uuid()
  const channels = state.channels
  const existingChannel = channels.get(channelName)

  let channel =
    existingChannel || client.subscribe(getFullChannelName({ channelName, userId, tenantId }))

  channel.bind(event, onEvent)

  dispatch({
    type: PusherActionType.SubscribedToChannelEvent,
    id: eventSubscriptionId,
    channel: channelName,
    event,
  })
  dispatch({ type: PusherActionType.AddChannel, channelName, channel })

  return () => {
    channel.unbind(event, onEvent)
    dispatch({ type: PusherActionType.UnsubscribedFromChannelEvent, id: eventSubscriptionId })
  }
}

const _trigger = ({
  channelName,
  event,
  data,
  state,
}: {
  channelName: ChannelName
  event: string
  data: any
  state: PusherState
}) => {
  const channel = state.channels.get(channelName)
  if (channel) {
    channel.trigger(event, data)
  }
}

/**
 * Connects to Pusher, subscribes to the passed channel, and binds the passed callback to the passed event.
 * @param channel The channel to subscribe to.
 * @param event The event to bind the callback to.
 * @param onEvent The callback to bind to the event.
 * @param enabled If the event subscription should be enabled.
 */
export const usePusherEvent = ({
  channel,
  event,
  onEvent,
  enabled = true,
}: {
  channel: ChannelName
  event: string
  onEvent: (data: any) => void
  enabled?: boolean
}) => {
  const { connected } = useContext(context)
  const { connect, subscribeToChannelEvent } = useContext(actionsContext)
  const tenantConfig = useTenantConfig()
  const unsubscribe = useRef<() => void>()

  // Prevents this hook (new Pusher) from being enabled when necessary
  if (!tenantConfig.campaigns.call_campaign_voip && !tenantConfig.voice.enabled) enabled = false

  useEffect(() => {
    // Connect to Pusher
    if (!connected && enabled) {
      connect()
    }

    // Subscribe when enabled
    if (connected && !unsubscribe.current && enabled) {
      unsubscribe.current = subscribeToChannelEvent({ channel, event, onEvent })
      return unsubscribe.current
    }

    // Unsubscribe when not enabled
    if (connected && unsubscribe.current && !enabled) {
      unsubscribe.current()
      unsubscribe.current = undefined
    }
  }, [enabled, connected])
}
