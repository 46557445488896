// @ts-nocheck FIXME
import React, { useContext } from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { Freshchat } from 'reactjs-freshchat'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import ErrorBoundary from './components/ErrorBoundary/error_boundary'
import { GlobalStyles } from '@leadrilla/pulsar'
import { routes, renderRoute } from './routes'
import AuthProvider, { UserContext } from './components/AuthProvider/auth_provider'
import NotificationBar from './components/NotificationBar/notification_bar'
import AppHelmet from './components/AppHelmet/app_helmet'
import { PusherProvider } from './hooks/pusher'
import { TooltipProvider } from './components/ui/tooltip'

import { TenantConfigProvider, useTenantConfig } from './hooks/TenantConfig'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import './styles/styles.scss'

const queryClient = new QueryClient()

try {
  sessionStorage.removeItem('pusher_enabled')
} catch (e) {
  console.log(e)
}

const UserSwitch = () => {
  const { user } = useContext(UserContext)

  return <Switch>{routes.map((route) => renderRoute(route, user))}</Switch>
}

const TenantConfiguredApp = () => {
  const tenantConfig = useTenantConfig()
  const stripePromise = !tenantConfig.stripe.use_connected_account_on_client
    ? loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {})
    : loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
        stripeAccount: tenantConfig.stripe.connected_stripe_account_id,
      })

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={tenantConfig.theme}>
        <AppHelmet />
        <GlobalStyles />
        {tenantConfig.freshchat_token && (
          <Freshchat token={'bb1226ca-45e1-45ee-9333-327ffcabdc86'} />
        )}
        <Elements stripe={stripePromise}>
          <NotificationBar>
            <UserSwitch />
          </NotificationBar>
        </Elements>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

render(
  <ErrorBoundary level="app">
    <Router>
      <AuthProvider>
        <TenantConfigProvider>
          <PusherProvider>
            <TooltipProvider delayDuration={200}>
              <TenantConfiguredApp />
            </TooltipProvider>
          </PusherProvider>
        </TenantConfigProvider>
      </AuthProvider>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root')
)
