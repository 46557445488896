import { Stack, Text, TextLink } from '@leadrilla/pulsar'
import { FallbackProps } from 'react-error-boundary'
import styled from '@emotion/styled'
import useImage from '../../hooks/image'
import { isLeadrilla, useTenant } from '../../hooks/TenantConfig'

export const PageError: React.FC<FallbackProps> = (props) => {
  console.error(props.error)
  return <PageErrorMessage />
}

export const PageErrorMessage = () => {
  const tenant = useTenant()
  const { image: errorImage } = useImage(`${tenant.name}-error.svg`)

  return (
    <Container>
      <Hero>
        {/* @ts-ignore */}
        {isLeadrilla() && <StyledImg src={errorImage} alt="Sad Gorilla Logo" />}
        <HeroTitle>Sorry!</HeroTitle>
        <HeroContent>
          <Stack space="xl">
            <Text component="p" size="huge">
              We are having some issues. Try {/* @ts-ignore */}
              <TextLink onClick={() => window.location.reload()}>reloading the page</TextLink>.
            </Text>
            <Text component="p" size="huge">
              If this problem continues, please contact support at {/* @ts-ignore */}
              <TextLink
                href={`mailto:${tenant.config.emails?.support_email}?subject=Page Error`}
                target="_blank"
              >
                {tenant.config.emails?.support_email}
              </TextLink>
              .
            </Text>
          </Stack>
        </HeroContent>
      </Hero>
    </Container>
  )
}

export default PageError

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
`
const Hero = styled.div`
  max-width: 600px;
  margin-top: 60px;
  margin-left: 16px;
  margin-right: 16px;
`

const HeroTitle = styled.div`
  margin-top: 32px;
  font-size: 4.2rem;
  font-weight: 800;
`

const HeroContent = styled.div`
  margin-top: 16px;
`

const StyledImg = styled.img`
  height: 10vh;
`
