import { useQuery } from '@tanstack/react-query'
import backend from '../../backend'

interface ShouldVoipBeEnabledResponse {
  call_campaign_exists: boolean
  enabled_call_campaign_id?: string
}

const fetchShouldVoipBeEnabled = async (): Promise<ShouldVoipBeEnabledResponse> => {
  const { body } = await backend.get('/voip/enabled')
  return body
}

export const useShouldVoipBeEnabled = () => {
  return useQuery<ShouldVoipBeEnabledResponse>({
    queryKey: ['should-voip-be-enabled'],
    queryFn: fetchShouldVoipBeEnabled,
  })
}
