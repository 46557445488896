import React, { useContext } from 'react'
import { Box, Text, Icon } from '@leadrilla/pulsar'
import styled from '@emotion/styled'
import { CallContext } from './CallContext'

const DialLead: React.FC = () => {
  const callContext = useContext(CallContext)

  return (
    <StyledBox display="flex" justifyContent="center" alignItems="center" height="400px">
      <CenteredIcon name="phone" tone="positive" size="xxl" />

      <StyledText size="bigger" weight="stronger" tone="light" align="center">
        {callContext.call?.type === 'outbound' ? <>Dialing Lead...</> : <>Incoming call...</>}
      </StyledText>
    </StyledBox>
  )
}
const StyledBox = styled(Box)`
  align-content: center;
`
const CenteredIcon = styled(Icon)`
  position: absolute;
  transform: translateX(12px) translateY(0);
  size: 80px;
  height: 100px !important;
  width: 100px !important;
  z-index: 10;
  color: var(--colors-hairline) !important;
  opacity: 0.4;
`
const StyledText = styled(Text)`
  z-index: 11;
`

export default DialLead
