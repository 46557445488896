import defaultTenantConfig from '../../tenant-config'
import { createContext, useEffect, useState, useContext } from 'react'
import backend from '../../backend'
import * as Sentry from '@sentry/react'
import { Theme } from '../../styles/themes'

export type TenantName =
  | 'leadrilla'
  | 'amerilife'
  | 'amerilife_test'
  | 'demo'
  | 'edm'
  | 'trking'
  | 'advocate'
  | 'leadprodigy'
  | 'consumeraffairs'
  | 'adpl'
  | 'mfg'
  | 'tarkenton'

export interface Tenant {
  id: string
  name: string
  config: TenantConfig
}
export interface TenantConfig {
  display_name: string
  tenant_url: string
  tenant_went_live_at: string
  auth?: {
    saml?: {
      callbackUrl: string
      issuer: string
      entryPoint: string
      signatureAlgorithm: 'sha256'
      wantAuthnResponseSigned: boolean
      profileFieldNames: {
        email: string
        firstName: string
        lastName: string
      }
    }
  }
  product_settings: { enabled: boolean }
  webhook_settings: { enabled: boolean }
  stripe: {
    charge_account: 'platform' | 'connected'
    statement_descriptor: string
    connected_stripe_account_id?: string
    use_connected_account_on_client?: boolean
    application_fee?: number
    stripe_receipts?: boolean
  }
  campaigns: {
    enabled: boolean
    ping_mode: 'bidding' | 'demand-only'
    call_charge_mode: 'answer' | 'buffer'
    call_auto_refund: boolean
    hide_unconverted_calls: boolean
    ipp_idle_timeout: number
    max_campaigns_per_call: number
    duplicate_lead_blocking_window: number
    blacklist_alliance_scrub?: boolean
    call_campaign_voip?: boolean
    call_campaign_voip_show_pause_reason_modal?: boolean
    show_simplified_product_step_product_name?: boolean
    hide_product_tooltip?: boolean
    targeting_method_allow_list?: TargetingMethod[]
    default_auto_recharge_to_off?: boolean
    appointments?: {
      enabled: boolean
    }
  }
  marketplace: {
    enabled: true
    second_chance: boolean
    times_sold?: number
    days_until_available: number
    pricing: {
      model: 'multiplier' | 'static'
      tiers: {
        start: number
        end: number
        multiplier?: number
      }[]
    }
    disable_minimum_order?: boolean
  }
  subscriptions: {
    enabled: boolean
    stripe_account?: string
    price?: number
  }
  signup: {
    invite_only: boolean
    collect_npn: boolean
    collect_industry: boolean
    collect_company: boolean
    collect_organization: boolean
    organization_display_name: ''
  }
  emails: {
    noreply_email: string
    support_email: string
    support_email_subject?: string
    lead_communication_email?: string
    logo_url: string
    dark_logo_url: string
    logo_width: number
    logo_height: number
    primary_color: string
    address: {
      street: string
      city: string
      state: string
      zip: string
    }
  }
  sms_from: string
  self_managed_twilio_account?: boolean
  voice: {
    enabled: boolean
    cost_per_minute?: number
  }
  referral_program: ReferralProgramEnabled | ReferralProgramDisabled
  custom_menu_links?: CustomMenuLink[]
  terms: {
    company_name: string
    company_legal_name?: string
    site: string
    address: string
  }
  logos?: {
    // TODO: dimensions for other logos
    dashboard_main?: {
      width: string
      height: string
    }
  }
  webhooks?: {
    lead_status_change?: string
  }
  mulesoft_events_base_url?: string
  themeColor: string
  theme: Theme
  mapTheme: {
    0.2: string
    0.4: string
    0.6: string
    0.8: string
  }
  call_campaign_video_id?: string
  enable_lead_print?: boolean
  return_user_info_on_post?: {
    enabled: boolean
    format_cost?: boolean
  }
  teams: {
    enabled: boolean
    default_members_table_filters: {
      role: 'show-members' | 'show-admins' | 'show-all'
    }
  }
  ipp_enabled_at: Date
  platform_presence: boolean
  title: string
  enabledStates?: string[] // TODO - make/find a type/enum for abbreviated state names
  disableMarketplace?: boolean
  display_powered_by_logo?: boolean
  freshchat_token?: string
  extra_terms?: {
    name: string
    url: string
    display_on_login?: boolean
  }
  sms_checkbox?: boolean
  hide_statewide_targeting?: boolean
  hide_radius_targeting?: boolean
  welcome_message?: string
  organizations:
    | { enabled: false }
    | {
        enabled: true
        collect_during_signup: boolean
        display_name: string
      }
  text_overrides?: {
    leads_title?: string
  }
  navigation?: {
    hide_faq?: boolean
  }
  hide_lead_info_map?: boolean
  hide_dnc_button?: boolean
}

interface ReferralProgramEnabled {
  enabled: true
  referrer_amount: number
  referee_amount: number
  required_spend_amount: number
  link_only?: boolean
}

interface ReferralProgramDisabled {
  enabled: false
}

interface CustomMenuLink {
  text: string
  icon: string
  href: string
}

enum TargetingMethod {
  Statewide = 'statewide',
  StateAndCounty = 'state_and_county',
  ZipCodeList = 'zip_code_list',
  Radius = 'radius',
}

export const isLeadrilla = () => {
  const tenant = localStorage.getItem('tenant')
  return tenant === 'leadrilla'
}

const defaultTenant = {
  id: '',
  name: 'leadrilla',
  config: defaultTenantConfig,
}

export const TenantContext = createContext<{
  tenant: Tenant
  refreshTenantConfig: () => void
}>({ tenant: defaultTenant, refreshTenantConfig: () => {} })

/** This hook is the preferred method for fetching the tenant config. */
export const useTenantConfig = () => useContext(TenantContext).tenant.config
export const useTenant = () => useContext(TenantContext).tenant
export const useRefreshTenantConfig = () => useContext(TenantContext).refreshTenantConfig

export const TenantConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [tenant, setTenant] = useState<Tenant>()
  const [isLoading, setIsLoading] = useState(true)

  const [fetchConfigToggle, setFetchConfigToggle] = useState(true)
  const refreshTenantConfig = () => setFetchConfigToggle(!fetchConfigToggle)

  const fetchConfig = async () => {
    const { body } = await backend.get('/config')
    if (!body) {
      setIsLoading(false)
      return
    }

    // Sentry initialization for production environment
    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        dsn: 'https://a4424c1991bd4ca190122f322cc4dc4c@o423442.ingest.sentry.io/5353932',
        environment: process.env.REACT_APP_VERCEL_URL,
        integrations: [Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false })],
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
      })
      Sentry.setTag('tenant', body.tenant.name)
    }

    // Update local storage and state with the new tenant configuration
    localStorage.setItem('tenant', body.tenant.name)
    setTenant(body.tenant)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchConfig()
  }, [fetchConfigToggle])

  return !isLoading && tenant ? (
    <TenantContext.Provider value={{ tenant, refreshTenantConfig }}>
      {children}
    </TenantContext.Provider>
  ) : !isLoading ? (
    <div>Error fetching Tenant Config. Is the API running?</div>
  ) : null
}
