// @ts-nocheck FIXME
import React from 'react'
import { Stack, Strong, Text, Box } from '@leadrilla/pulsar'
import { getLocalizedDate } from '../../helpers/time'

function LeadEmailPreview(props) {
  return (
    <div>
      <Box marginTop="s" marginBottom="xs">
        <Text component="label">
          <Strong>To:</Strong>
        </Text>
      </Box>

      <div>{props.lead.email}</div>
      <Box marginTop="s" marginBottom="xs">
        <Text component="label">
          <Strong>Subject:</Strong>
        </Text>
      </Box>
      <div>Your info request</div>
      <Box marginTop="s" marginBottom="xs">
        <Text component="label">
          <Strong>Message:</Strong>
        </Text>
      </Box>
      <Stack space="m">
        <p>{props.lead.message}</p>
        <p>
          Here is the information you submitted. If any of this is incorrect, please contact{' '}
          {props.lead.first_name} {props.lead.last_name} as soon as possible.
        </p>
        <p>
          Name: {props.lead.first_name} {props.lead.last_name}
          <br />
          Phone: {props.lead.phone ? props.lead.phone.formatNational() : '-'}
          <br />
          Street Address: {props.lead.street_address}
          <br />
          City, State, Zip: {`${props.lead.city}, ${props.lead.state}, ${props.lead.zip}`}
          <br />
          Date of Birth: {getLocalizedDate(props.lead.birthdate)}
        </p>
        <p>Thanks!</p>
      </Stack>
    </div>
  )
}

export default LeadEmailPreview
