import styled from '@emotion/styled'
import { Box, Icon } from '@leadrilla/pulsar'
import useImage from '../../hooks/image'

interface ICallSpinnerProps {
  spinner?: boolean
}

const CallSpinnerIcon = ({ spinner }: ICallSpinnerProps) => {
  const { image: spinnerImage } = useImage('spinner.svg')

  return (
    <Box position="relative">
      <WhiteCircle />
      {spinnerImage && spinner && (
        <CenteredSpinner src={spinnerImage} height={44} width={44} alt="spinner" />
      )}
      <CenteredIcon name="phone" tone="positive" size="m" />
    </Box>
  )
}

const WhiteCircle = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: white;
`
const CenteredSpinner = styled.img`
  position: absolute;
  top: 0;
  animation-name: spinner;
  animation-duration: 1.3s;
  animation-timing-function: linear;

  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  display: inline-block;
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
const CenteredIcon = styled(Icon)`
  position: absolute;
  transform: translateX(14px) translateY(-29px);
`

export default CallSpinnerIcon
