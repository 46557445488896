import { Box, Icon, Inline, Stack, Text, TextLink } from '@leadrilla/pulsar'
import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { UserContext } from '../AuthProvider/auth_provider'
import { useTenant } from '../../hooks/TenantConfig'
import { downloadCSV } from './helpers'

const SubscriptionInactive = () => {
  const { user } = useContext(UserContext)
  const tenant = useTenant()
  const exportLeadURL = '/leads?all_fields=true'

  return (
    <>
      <Box flexGrow={1} marginTop={['xl', 'xl', 'xxl']}>
        <Stack space="m">
          <Box display="flex" justifyContent={['center', 'start']} alignItems={['center', 'start']}>
            <HeaderText weight="fat">Your subscription is no longer active.</HeaderText>
          </Box>
          <Text component="p" size={['big', 'bigger']}>
            Please update your payment information to reactivate your subscription and regain access
            to the platform.
          </Text>
        </Stack>
      </Box>
      <Box display="flex" alignItems="end" marginTop={['m', 'xl']} flexGrow={1}>
        <Stack space="m">
          <Box paddingY={['xxs', 'none']}>
            <TextLink onClick={() => downloadCSV(tenant.name, exportLeadURL)} href="#">
              <Inline space="s" alignY="center" nowrap>
                <Icon size="m" name="archive-tt" />
                <Text component="p" weight="stronger" tone="action">
                  Download my leads
                </Text>
              </Inline>
            </TextLink>
          </Box>
          {/* @ts-expect-error FIXME */}
          {user.points > 0 && (
            <Box paddingY={['xxs', 'none']}>
              <TextLink
                href={`mailto:${tenant.config.emails?.support_email}?subject=Request to withdraw funds from account balance`}
              >
                <Inline space="s" alignY="center" nowrap>
                  <Icon size="m" name="price-tt" />
                  <Text component="p" weight="stronger" tone="action">
                    Withdraw my account balance
                  </Text>
                </Inline>
              </TextLink>
            </Box>
          )}
        </Stack>
      </Box>
    </>
  )
}

const HeaderText = styled(Text)`
  font-size: 32px;
  @media (min-width: 960px) {
    font-size: 42px;
  }
`

export default SubscriptionInactive
