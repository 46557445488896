import React from 'react'
import { Dialog, Heading, Icon, Inline, Text, TextLink } from '@leadrilla/pulsar'
import styled from '@emotion/styled'

const MigrationModal = ({
  open,
  setShowMigrationModal,
  tenant,
}: {
  open: boolean
  setShowMigrationModal: (prev: boolean) => void
  tenant: string
}) => {
  return (
    <Dialog open={open} onClose={() => setShowMigrationModal(false)} width="content" inset="m">
      <div className="h-[300px] w-[600px] space-y-8 px-8">
        <div className="flex items-center justify-between py-4">
          <Heading level={1}>Notice</Heading>
          <div className="cursor-pointer">
            <Icon name="close" size="l" onClick={() => setShowMigrationModal(false)} />
          </div>
        </div>
        {tenant === 'trking' ? trkingContent : genericContent}
      </div>
    </Dialog>
  )
}

const StyledSpan = styled.span`
  font-weight: bold;
`
// Keeping this here for likely use when migrating the remaining tenants. The date will need updated.
const genericContent = (
  <>
    <Text>
      Please be aware that we will be performing a system update on{' '}
      {<StyledSpan>Wednesday, Feb. 21st, 2024.</StyledSpan>}
    </Text>
    <Text>
      We are excited to announce that we will be making significant updates to our platform. Our
      focus will be on enhancing our publisher integrations and utilizing higher-quality vendors,
      which will result in a better cadence of high-quality leads and calls within the platform,
      improving your experience in real-time leads and call volume.
    </Text>
    <Text>
      To ensure that we meet your expectations and continue to provide high-quality service, we will
      be increasing the price of leads and calls. We understand that this is a significant change,
      but we believe that it's necessary to enable us to better serve you.
    </Text>
    <Text>
      We appreciate your support and understanding as we continue to work hard to improve our
      platform. If you have any issues or concerns, please don't hesitate to reach out to our team.
      Service will continue as normal after the maintenance is complete.
    </Text>
  </>
)

const trkingContent = (
  <>
    <Text>
      By using the LeadStream Lead Portal software, you acknowledge that all sales are final.
    </Text>
    <Text>
      Additionally, you confirm that you have gone through our FAQs and watched our training video
      to ensure your success on the platform.
    </Text>
    <Inline alignX="between" alignY="center">
      <Text>You can access the FAQs and training video by visiting this page:</Text>
      <TextLink href={`https://www.trkingim.com/leadstream-lead-portal`} target="_blank">
        www.trkingim.com/leadstream-lead-portal
      </TextLink>
    </Inline>
  </>
)

export default MigrationModal
