import React, { useReducer, useState } from 'react'
import { Toast } from '@leadrilla/pulsar'
import { useTenantConfig } from '../../hooks/TenantConfig'

// FIXME improve default value
export const NotificationContext = React.createContext({
  sendNotification: () => {},
})

const typesMap = {
  success: 'success',
  error: 'critical',
  caution: 'caution',
  info: 'info',
}

// @ts-expect-error FIXME
function NotificationBar(props) {
  const tenantConfig = useTenantConfig()

  // @ts-expect-error FIXME
  const reducer = (state, action) => {
    switch (action.type) {
      case 'hide':
        return {
          show: false,
          type: state.type,
        }
      case 'error':
        return {
          show: true,
          // @ts-expect-error FIXME
          type: typesMap[action.type],
          message: action.message || (
            <>
              Oops, something went wrong! If the problem persists, please email us at{' '}
              <a href={tenantConfig.emails?.support_email}>{tenantConfig.emails?.support_email}</a>.
            </>
          ),
        }
      default:
        return {
          show: true,
          // @ts-expect-error FIXME
          type: typesMap[action.type],
          message: action.message,
        }
    }
  }

  // @ts-expect-error FIXME
  const [state, dispatch] = useReducer(reducer, { show: false })
  const [timeoutId, setTimeoutId] = useState()

  // @ts-expect-error FIXME
  const sendNotification = (action) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    if (action.type !== 'hide') {
      // @ts-expect-error FIXME
      setTimeoutId(setTimeout(() => dispatch({ type: 'hide' }), 10000))
    }

    // @ts-expect-error FIXME
    dispatch(action)
  }

  return (
    <>
      <Toast
        show={state.show}
        type={state.type}
        message={state.message}
        // @ts-expect-error FIXME
        onClose={() => dispatch({ type: 'hide' })}
      />

      {/* @ts-expect-error FIXME */}
      <NotificationContext.Provider value={sendNotification}>
        {props.children}
      </NotificationContext.Provider>
    </>
  )
}

export default NotificationBar
