import { useCallback, useEffect } from 'react'

const Keypad = ({
  onKeyPress,
  enableBackspace = false,
}: {
  onKeyPress: (key: string) => void
  enableBackspace?: boolean
}) => {
  const allKeys = keys.map(({ key }) => key) as string[]

  // Allow input with a keyboard
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (allKeys.includes(event.key) || (enableBackspace && event.key === 'Backspace')) {
        onKeyPress(event.key)
      }
    },
    [onKeyPress]
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [onKeyDown])

  return (
    <div className="grid w-[198px] grid-cols-3 gap-6">
      {keys.map(({ key, content }) => (
        <button
          key={key}
          className="flex h-[56px] w-[56px] select-none flex-col items-center justify-center rounded-full shadow-[0_0_8px_rgba(0,0,0,0.1)]"
          onClick={() => onKeyPress(key)}
        >
          <div className="text-[20px] font-semibold">{key}</div>
          <div className="h-[13px] text-[10px] tracking-wider">{content}</div>
        </button>
      ))}
    </div>
  )
}

const keys = [
  {
    key: '1',
    content: '',
  },
  {
    key: '2',
    content: 'ABC',
  },
  {
    key: '3',
    content: 'DEF',
  },
  {
    key: '4',
    content: 'GHI',
  },
  {
    key: '5',
    content: 'JKL',
  },
  {
    key: '6',
    content: 'MNO',
  },
  {
    key: '7',
    content: 'PQRS',
  },
  {
    key: '8',
    content: 'TUV',
  },
  {
    key: '9',
    content: 'WXYZ',
  },
  {
    key: '*',
    content: '',
  },
  {
    key: '0',
    content: '+',
  },
  {
    key: '#',
    content: '',
  },
]

export { Keypad }
