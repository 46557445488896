import React, { useState, useEffect } from 'react'
import { TextArea, TextAreaProps, Stack, Text, Inline } from '@leadrilla/pulsar'

export interface TextAreaWithCharacterCountProps extends TextAreaProps {
  value: string
  required?: boolean
  maxLength?: number
}

const TextAreaWithCharacterCount = (props: TextAreaWithCharacterCountProps) => {
  const [inputValue, setInputValue] = useState(props.value)
  const [characterCount, setCharacterCount] = useState(props.value?.length || 0)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    if (!props.maxLength || input.length <= props.maxLength) setInputValue(event.target.value)
  }

  useEffect(() => {
    setCharacterCount(inputValue.length)
  }, [inputValue])

  return (
    <Stack space={'s'}>
      <TextArea
        {...props}
        value={inputValue}
        onChange={(event) => {
          handleInputChange(event)
          props.onChange(event)
        }}
      ></TextArea>
      <Inline alignX={'between'}>
        <Text tone={'light'} size={'small'}>
          {props.required && '*Required'}
        </Text>
        <Text>
          {characterCount}
          {props.maxLength && `/${props.maxLength}`}
        </Text>
      </Inline>
    </Stack>
  )
}

export default TextAreaWithCharacterCount
