import React from 'react'
import { Link } from 'react-router-dom'

function MaintenanceMode() {
  return (
    <div className="grid grid-cols-12 items-center" style={{ minHeight: '100%' }}>
      <div className="lr-large-text col-span-12 text-center">
        <div>
          The platform is currently down for scheduled maintenance, which is expected to last one
          hour.
        </div>
        <Link to="/" className="blue-text">
          Return to home page
        </Link>
      </div>
    </div>
  )
}

export default MaintenanceMode
