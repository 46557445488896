// @ts-nocheck FIXME
import React from 'react'
import { Box } from '@leadrilla/pulsar'
import styled from '@emotion/styled'

import ScrollToTop from '../../components/ScrollToTop/scroll_to_top'
import Navbar from '../../components/Navbar/navbar'

function WebsiteMain(props) {
  return (
    <ScrollToTop>
      {/* Navbar */}
      <Navbar {...props} />
      <StyledContentWrapper>{props.children}</StyledContentWrapper>
    </ScrollToTop>
  )
}

const StyledContentWrapper = styled(Box)`
  padding-top: 58px;
`

export default WebsiteMain
