import { Dialog, Heading, Text } from '@leadrilla/pulsar'

const AlertUserToRefreshModal = ({
  refreshModalIsOpen,
  setRefreshModalIsOpen,
}: {
  refreshModalIsOpen: boolean
  setRefreshModalIsOpen: (prev: boolean) => void
}) => {
  return (
    <Dialog open={refreshModalIsOpen} onClose={() => setRefreshModalIsOpen(false)} inset="m">
      <div className="px-8 pt-12">
        <Heading level={3}>A new version of this application is available</Heading>
      </div>
      <div className="cursor-pointer px-8 py-12" onClick={() => window.location.reload()}>
        <Text weight="stronger" tone="action">
          Please refresh your page to continue.
        </Text>
      </div>
    </Dialog>
  )
}

export default AlertUserToRefreshModal
