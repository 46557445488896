import backend from '../../backend'
import { find } from 'lodash'
import papa from 'papaparse'
import { saveAs } from 'file-saver'
import { DateTime } from 'luxon'
import { SubscriptionResponse } from '../SubscriptionCard/subscription_card'

const dateFormatter = (val: string | number) => {
  if (typeof val === 'string') {
    return DateTime.fromISO(val).toUTC().toISODate()
  }
  return ''
}

const csvColumns = [
  { header: 'product', key: 'product_name' },
  { header: 'name', key: 'name' },
  { header: 'email', key: 'email' },
  { header: 'phone', key: 'phone' },
  { header: 'birthdate', key: 'birthdate', formatter: dateFormatter },
  { header: 'street address', key: 'street_address' },
  { header: 'city', key: 'city' },
  { header: 'state', key: 'state' },
  { header: 'zip', key: 'zip' },
  { header: 'date purchased', key: 'created_at', formatter: dateFormatter },
  { header: 'current status', key: 'current_status' },
]

export const downloadCSV = async (tenantName: string, url: string) => {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const yyyy = today.getFullYear()

  const csvName = yyyy + mm + dd + `-${tenantName}.csv`

  const { body } = await backend.get(`${url}`)

  const data = body.data.map((data: any) => {
    const formatted = data
    for (const key of Object.keys(data)) {
      const column = find(csvColumns, { key })
      if (column) {
        if (column.formatter) {
          formatted[column.header] = column.formatter(data[key])
        } else {
          formatted[column.header] = data[key]
        }
      }
    }
    return formatted
  })

  let columns

  if (csvColumns) {
    columns = csvColumns.map((column) => column.header)
  }

  const csv = papa.unparse(data, { header: true, columns })

  saveAs(new Blob([csv]), csvName)
}

export const getSubscriptionDate = (subscription: SubscriptionResponse) => {
  return subscription
    ? DateTime.fromSeconds(subscription?.current_period_end).toFormat('M/d/yyyy')
    : null
}
