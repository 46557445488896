import { createContext, useContext, useState } from 'react'
import { findIndex } from 'lodash'
import { LeadFeed } from '../types/lead_feed'
import backend from '../backend'
import { usePusherEvent } from './pusher'
import { useTenantConfig } from './TenantConfig'
import CampaignPauseReasonModal from '../AgentPortal/Campaigns/campaign_pause_reason_modal'

interface CampaignsContextType {
  campaigns: LeadFeed[]
  loading: boolean
  updatedCampaign?: UpdatedCampaignType
  fetchCampaigns: () => Promise<void>
  pauseCampaign: (id: string) => void
  pauseCampaignWithReason: (id: string, afterPause?: () => void) => void
  updateCampaign?: (id: string, patch: Partial<LeadFeed>) => void
}
interface UpdatedCampaignType {
  id: string
  enabled: boolean
}
const CampaignsContext = createContext<CampaignsContextType>({
  campaigns: [],
  loading: true,
  fetchCampaigns: () => Promise.resolve(),
  pauseCampaign: () => {},
  pauseCampaignWithReason: () => {},
})

export const CampaignsProvider = (props: { children: JSX.Element }) => {
  const [campaigns, setCampaigns] = useState<LeadFeed[]>([])
  const [updatedCampaign, setUpdatedCampaign] = useState<UpdatedCampaignType>()
  const [loading, setLoading] = useState(true)

  const fetchCampaigns = async () => {
    if (!loading) setLoading(true)
    const { body } = await backend.get('/lead-feeds')

    setCampaigns(body.data)
    setLoading(false)
  }

  const updateCampaign = (id: string, patch: Partial<LeadFeed>) => {
    if (campaigns && campaigns.length > 0) {
      const index = findIndex(campaigns, { id })
      if (index > -1) {
        const copy = [...campaigns]
        copy[index] = {
          ...copy[index],
          ...patch,
        }
        setCampaigns(copy)
      }
    }
    if (patch.enabled !== undefined) setUpdatedCampaign({ id, enabled: patch.enabled })
  }

  const [afterPauseCallback, setAfterPauseCallback] = useState<() => void>()
  const [pauseCampaignId, setPauseCampaignId] = useState<string>()
  const [showCampaignPauseReasonModal, setShowCampaignPauseReasonModal] = useState(false)

  const pauseCampaignWithReason = (id: string, afterPause?: () => void) => {
    setAfterPauseCallback(() => afterPause)
    setPauseCampaignId(id)
    setShowCampaignPauseReasonModal(true)
  }

  const pauseCampaign = (id: string) => {
    backend
      .put(`/lead-feeds/${id}/pause`, { reason: 'idle' })
      .then(() => updateCampaign(id, { enabled: false }))
  }

  useListenForPausedCallCampaign(campaigns, updateCampaign)

  return (
    <CampaignsContext.Provider
      value={{
        campaigns,
        loading,
        updatedCampaign,
        fetchCampaigns,
        updateCampaign,
        pauseCampaign,
        pauseCampaignWithReason,
      }}
    >
      {props.children}

      {!!pauseCampaignId && (
        <CampaignPauseReasonModal
          open={showCampaignPauseReasonModal && !!pauseCampaignId}
          setOpen={setShowCampaignPauseReasonModal}
          pauseAll={false}
          campaignId={pauseCampaignId}
          afterSubmit={() => {
            updateCampaign(pauseCampaignId!, { enabled: false })
            if (afterPauseCallback) afterPauseCallback()
            setPauseCampaignId(undefined)
            setAfterPauseCallback(undefined)
          }}
        />
      )}
    </CampaignsContext.Provider>
  )
}

export const useCampaigns = () => useContext(CampaignsContext)

// If there is an active call campaign, we listen for campaign-paused Pusher events
// to reflect that state change in real time
const useListenForPausedCallCampaign = (
  campaigns: LeadFeed[],
  updateCampaign: (id: string, patch: Partial<LeadFeed>) => void
) => {
  const tenantConfig = useTenantConfig()
  const enabledCallCampaigns = campaigns.filter(
    (campaign) => campaign.enabled && campaign.product?.type === 'calls'
  )
  const shouldSubscribe =
    tenantConfig.campaigns.call_campaign_voip && enabledCallCampaigns.length > 0

  usePusherEvent({
    channel: 'private-voip',
    event: 'campaign-paused',
    onEvent: (campaign: LeadFeed) => updateCampaign(campaign.id, { enabled: false }),
    enabled: shouldSubscribe,
  })
}
