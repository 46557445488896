import { Box, PrimaryButton, Stack, Text } from '@leadrilla/pulsar'
import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import useImage from '../../hooks/image'
import { isLeadrilla, useTenant } from '../../hooks/TenantConfig'

interface SuccessPanelProps {
  children: ReactNode
}

const SuccessPanel = ({ children }: SuccessPanelProps) => {
  const tenant = useTenant()

  const { image: iconLogo } = useImage(
    isLeadrilla() ? 'leadrilla-logo-blue.svg' : `${tenant.name}-logo.svg`
  )

  return (
    <Box maxWidth={['300px', '600px']}>
      <Stack space="l">
        <Box maxWidth={['120', '200']} marginBottom={'l'}>
          <StyledLogo src={iconLogo || ''} alt={'platform logo'} />
        </Box>
        {children}
      </Stack>
    </Box>
  )
}

interface HeaderProps {
  children: ReactNode
}

const Heading = ({ children }: HeaderProps) => {
  return <StyledWelcomeText>{children}</StyledWelcomeText>
}

SuccessPanel.Heading = Heading

const BodyContent = ({ children }: HeaderProps) => {
  return <>{children}</>
}

SuccessPanel.BodyContent = BodyContent

interface ButtonContentProps {
  children: ReactNode
  close: () => void
}

const ButtonContent = ({ children, close }: ButtonContentProps) => {
  return (
    <Box marginTop="xl">
      <PrimaryButton
        disabled={false}
        onClick={() => {
          close()
        }}
        size="bigger"
      >
        {children}
      </PrimaryButton>
    </Box>
  )
}

SuccessPanel.ButtonContent = ButtonContent

const StyledWelcomeText = styled(Text)`
  font-size: 32px;
  font-weight: 800;
  @media (min-width: 600px) {
    font-size: 48px;
    font-weight: 800;
  }
`

const StyledLogo = styled('img')`
  align-self: center;
  max-height: 65px;
`

export default SuccessPanel
