import {
  Box,
  DestructiveButton,
  Divider,
  SecondaryButton,
  Stack,
  Strong,
  Text,
} from '@leadrilla/pulsar'
import { getSubscriptionDate } from '../../helpers/time'
import React, { useContext, useState } from 'react'
import { SubscriptionResponse } from '../SubscriptionCard/subscription_card'
import backend from '../../backend'
import { UserContext } from '../AuthProvider/auth_provider'
import { useNotification } from '../../hooks/notification'

interface SubscriptionCancelProps {
  goBack: () => void
  subscription: SubscriptionResponse
  success: any
}

const SubscriptionCancel = ({ goBack, subscription, success }: SubscriptionCancelProps) => {
  const { user, setUser } = useContext(UserContext)
  const [submitting, setSubmitting] = useState(false)
  const showNotification = useNotification()

  const cancelSubscription = async () => {
    setSubmitting(true)
    const { status, body } = await backend.delete('/subscriptions')
    if (status === 200) {
      if (body.subscription) {
        // @ts-expect-error FIXME
        setUser({ ...user, subscription: body.subscription })
      }
      success()
    } else {
      // @ts-expect-error FIXME
      showNotification({ type: 'error' })
    }
    setSubmitting(false)
  }

  return (
    <Box maxWidth="380px" display="block" inset="l">
      <Stack space="m">
        <Text component="p" size="big" weight="stronger">
          Are you sure you want to cancel your subscription?
        </Text>
        <Divider />
        <Text component="p">
          Your subscription will remain active until{' '}
          <Strong>{getSubscriptionDate(subscription.current_period_end)}</Strong>. After your
          subscription ends, any active campaigns will be paused and you will not be able to access
          your account until you re-subscribe.
        </Text>
        <Divider />
        <Box display="flex" justifyContent="between">
          <Box width="50%" paddingRight="m">
            <SecondaryButton onClick={goBack} fullWidth>
              Back
            </SecondaryButton>
          </Box>
          <Box width="50%">
            <DestructiveButton disabled={submitting} onClick={cancelSubscription} fullWidth>
              Cancel Subscription
            </DestructiveButton>
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}

export default SubscriptionCancel
