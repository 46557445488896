// @ts-nocheck FIXME
export const analyticsEvent = (event, properties) => {
  if (window.location.hostname !== 'leadrilla.com') return
  try {
    window.analytics.track(event, { ...properties })
  } catch (e) {
    console.error(e)
  }
}
/**
 * https://segment.com/docs/getting-started/04-full-install/#using-analyticsreset
 */
export const resetIdentity = () => {
  try {
    window.analytics.reset()
  } catch (e) {
    console.error(e)
  }
}

const userProperties = [
  'id',
  'type',
  'first_name',
  'last_name',
  'email',
  'phone',
  'created_at',
  'organization_id',
]
const prefix = 'Leadrilla | ' // todo - change for whitelabels whenever we launch segment on other platforms
/**
 * https://segment.com/docs/connections/spec/identify/
 * @param user
 */
export const setUserProperties = (user) => {
  if (window.location.hostname !== 'leadrilla.com') return
  try {
    let properties = {}
    userProperties.forEach((prop) => {
      if (user[prop]) {
        properties[prefix + prop] = user[prop]
      }
    })
    if (user.email && !user.impersonator) {
      window.analytics?.identify(user.email, { ...properties })
      window._cio?.identify({ email: properties.email })
    }
  } catch (e) {
    console.error('Segment ERROR: ', e)
  }
}
