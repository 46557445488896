import { Dialog } from '@leadrilla/pulsar'
import React from 'react'

export const StripeIframe = ({
  redirectUrl,
  open,
  onClose,
}: {
  redirectUrl: string
  open: boolean
  onClose: () => void
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <iframe title="stripe_3ds_iframe" src={redirectUrl} width="600" height="400" />
    </Dialog>
  )
}
