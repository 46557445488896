import React from 'react'
import { Badge } from '@leadrilla/pulsar'
interface props {
  data: any
}
const FormatStatus: React.FC<props> = ({ data }: props) => {
  const leadStatus = data[0]?.lead_status
  return <>{leadStatus && <Badge tone={leadStatus.tone}>{leadStatus.name}</Badge>}</>
}
export default FormatStatus
